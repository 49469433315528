import { stripRichTextWrapperTag } from '@/utils/html';
import getContact from '@/utils/adapters/contact';

const getData = (
  { primary, items },
  { $i18n, $enhancedLinkSerializer },
  borders = false
) => {
  const link = $enhancedLinkSerializer(primary.contact_push_link);
  const contacts = [];
  items.forEach(({ contact }) => {
    if (contact && contact.data) {
      contacts.push(getContact(contact.data));
    }
  });

  return {
    title: stripRichTextWrapperTag(primary.contact_push_title),
    cta: link
      ? {
          label: $i18n.t('cta.voir-tous-les-contacts'),
          ...link,
        }
      : null,
    contactsItem: contacts,
    borders,
  };
};

export const WithBorders = (sliceData, data, context) => {
  return getData(sliceData, context, true);
};

export default (sliceData, data, context) => {
  return getData(sliceData, context);
};
